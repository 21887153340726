<template>
   <div class="overall">
      <!-- 上半部分 -->
      <el-card class="chart-card">
         <!-- 上半部分右边内容 -->
         <div class="plane">
            <!-- 右边头部 -->
            <div class="ritherd">
               <el-col :span="8">
                  <div class="impose">
                     <el-card class="a" shadow="hover">
                        <div class="amount">
                           <div class="union">
                              <el-icon size="35px" color="#ffffff">
                                 <WalletFilled />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count">{{ departmentCount }}</div>
                              <div class="crew">总派发积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
               <el-col :span="8">
                  <div class="impose1">
                     <el-card shadow="hover">
                        <div class="amount">
                           <div class="union1">
                              <el-icon size="35px" color="#ffffff">
                                 <Van />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count1">{{ userCount }}</div>
                              <div class="crew">在途积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
               <el-col :span="8">
                  <div class="impose1">
                     <el-card shadow="hover">
                        <div class="amount">
                           <div class="union2">
                              <el-icon size="35px" color="#ffffff">
                                 <ShoppingCartFull />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count2">{{ integralCount }}</div>
                              <div class="crew">已换购积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
               <el-col :span="8">
                  <div class="impose1">
                     <el-card shadow="hover">
                        <div class="amount">
                           <div class="union3">
                              <!-- <el-icon size="35px" color="#ffffff"><ShoppingCartFull /></el-icon> -->
                              <img class="guoqi" src="../../assets/guoqi.png" alt="">
                           </div>
                           <div class="magnitude">
                              <div class="count3">{{ verywhere }}</div>
                              <div class="crew">过期积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
            </div>
            <!-- 积分派发 -->
            <div class="plane">
               <!-- <div>
            积分派发：
          </div> -->
               <div class="distribute">
                  <div class="payout">
                     可派发积分：{{ kepaifa }}积分
                  </div>
                  <el-upload v-model:file-list="fileList" class="upload-demo" action :auto-upload="true"
                     :show-file-list="false" name="excelFile" accept=".xlsx,.xls" limit='20' :on-error="error"
                     :http-request="uploadHttpRequest" ref='pleased' :disabled='jinyong'>
                     <el-button :type="batch == 1 ? 'primary' : 'info'" :loading="acting" :disabled='jinyong'>{{ duty
                        }}</el-button>
                  </el-upload>
                  <!-- <el-button type="primary" size="default" :loading='whirl'>批量派发</el-button> -->
                  <el-button type="warning" size="default" @click="download" :loading='whirl'>下载派发模板</el-button>
                  <!-- 积分日志下拉框 -->
                  <el-dropdown style="margin-left: 12px;" loading split-button type="primary" @command="pointsLog"
                     :hide-on-click="around">
                     积分日志
                     <div class="loading" v-show="wrought"></div>
                     <template #dropdown>
                        <el-dropdown-menu style="width: 117px;">
                           <el-dropdown-item command="failurePoints">派发失败日志</el-dropdown-item>
                           <el-dropdown-item command="purchasePoints">购买积分日志</el-dropdown-item>
                           <el-dropdown-item command="returnPoints">返还积分日志</el-dropdown-item>
                        </el-dropdown-menu>
                     </template>
                  </el-dropdown>
                  <!-- <el-button type="danger" size="default" @click="lose" :loading='whirl'>派发失败日志</el-button>
             <el-button type="success" size="default" @click="goumai" :loading='whirl'>购买积分日志</el-button> -->
                  <!-- 积分管理下拉框 -->
                  <el-dropdown style="margin-left: 12px;" loading split-button type="primary"
                     @command="pointsManagement" :hide-on-click="around">
                     积分管理
                     <div class="loading" v-show="wrought"></div>
                     <template #dropdown>
                        <el-dropdown-menu style="width: 117px;">
                           <el-dropdown-item command="pointsRecovery">积分回收</el-dropdown-item>
                           <el-dropdown-item command="pointDeduction">积分代扣</el-dropdown-item>
                           <el-dropdown-item command="pointsExpired">积分过期</el-dropdown-item>
                        </el-dropdown-menu>
                     </template>
                  </el-dropdown>
                  <!-- <el-button class="recall" size="default" @click="withdraw" :loading='whirl'>积分回收</el-button> -->
                  <!-- withhold -->
                  <!-- <el-button class="recall" size="default" @click="deduct" :loading='whirl'>积分代扣</el-button> -->
                  <!-- withhold -->
                  <!-- <el-button class="recall" size="default" @click="exceed" :loading='whirl'>积分过期</el-button> -->

                  <el-dropdown style="margin-left: 12px;" loading split-button type="primary" @command="handleCommand"
                     :hide-on-click="around">
                     导出
                     <div class="loading" v-show="wrought"></div>
                     <!-- 导出 -->
                     <template #dropdown>
                        <el-dropdown-menu style="width: 117px;">
                           <el-dropdown-item command="personage">派发积分合计</el-dropdown-item>
                           <el-dropdown-item command="personage1">派发积分明细</el-dropdown-item>
                           <el-dropdown-item command="personage2">在途积分合计</el-dropdown-item>
                           <el-dropdown-item command="personage3">换购积分合计</el-dropdown-item>
                        </el-dropdown-menu>
                     </template>
                  </el-dropdown>
               </div>
            </div>
            <!-- 表格内容 -->
            <!-- 搜索框 -->
            <div>
               <div class="grabble">
                  <div style="width: 70%;display: flex;">
                     <!-- 姓名 -->
                     <el-input class="hunt" v-model="designation" placeholder="请输入会员姓名" size="large" @input="orangutan"
                        clearable />
                     <!-- 编码 -->
                     <el-input class="hunt" v-model="serial" placeholder="请输入会员编码" size="large" @input="howl"
                        clearable />
                     <!-- 会员组织名称搜索 -->
                     <el-select class="hunt" v-model="ability" placeholder="请选择组织名称" size="large" @click="multiple"
                        clearable @change="numerical" @focus="closeIt" ref="selectIt" />
                     <!-- 派发时间 -->
                     <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" size="large" @change="immediUpdate" />
                     <!-- 搜索 -->
                     <el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
                        :loading='formative'><el-icon class="cancelgu">
                           <Search />
                        </el-icon>搜索</el-button>
                  </div>
               </div>
               <div class="statistics">
                  统计：{{ zong }}条
               </div>
            </div>
            <!-- 表格 -->
            <el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
               :header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts" :key="1">
               <!-- 会员姓名 -->
               <el-table-column align="center" label="会员姓名" prop="name" :show-overflow-tooltip='true' />
               <!-- 会员编号 -->
               <el-table-column label="会员编码" prop="username" align="center" :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- 所属工会 -->
               <el-table-column label="所属工会" prop="unionName" align="center" :formatter="quantum"
                  :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- 积分值 -->
               <el-table-column label="积分值" prop="integralValue" align="center" :formatter="quantum"
                  :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- 派发人 -->
               <el-table-column label="派发人" prop="adminName" align="center" :formatter="quantum"
                  :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- 派发时间 -->
               <el-table-column label="派发时间" prop="createTime" align="center" :formatter="reach"
                  :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- 备注 -->
               <el-table-column label="备注" prop="reason" align="center" :formatter="quantum"
                  :show-overflow-tooltip='true'>
               </el-table-column>
               <!-- <el-table-column label="操作" align="center" width="250" >
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.userId)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>历史查看</el-button
            >
          </template>
        </el-table-column> -->
            </el-table>
            <!-- 表格分页 -->
            <!-- 一页时候隐藏 hide-on-single-page="true" -->
            <el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
               :current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
               @current-change="handleCurrentChange" />
         </div>
         <!-- <div class="floating" @click="ocean">
        <img class="images" src="../../assets/xiazai.png" alt="">
      </div> -->
      </el-card>
      <!-- 所属组织弹窗 -->
      <div>
         <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
            <el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
         </s3-layer>
      </div>
      <!-- 导出提示弹窗 -->
      <div>
         <s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
            <div class="task">
               {{ quoth }}
            </div>
         </s3-layer>
      </div>

      <!-- <div>
			<s3-layer v-model="anew1" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth1}}
				</div>
			</s3-layer>
		</div> -->

      <!-- <el-dialog v-model="centre" width="50%">
      <template #header>
        <div class="minitype">预约下载中心</div>
        <div class="trousers">
          <img class="tishi" src="../../assets/tishi.png" alt="">
          <div>文件生成后仅当天有效，过期自动删除</div>
        </div>
      </template> 
      
        <el-table :data="table" style="width: 100%" :header-cell-style="{ background: '#F5F7FA' }" border :empty-text="subscribe">
          <el-table-column prop="createTime" label="申请时间"  width="150" align="center" :formatter="own" :show-overflow-tooltip='true' />
          <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip='true' />
          <el-table-column prop="fileName" label="文件名"  align="center" :show-overflow-tooltip='true'/>
          <el-table-column prop="state" label="状态" :formatter="condition"  align="center" :show-overflow-tooltip='true'/>
          <el-table-column label="操作" prop="state" align="center" width="100" >
            <template #default="scope">
              <el-button
                v-show="scope.row.state==1"
                size="default"
                @click="copyreader(scope.row.link)"
                class="bulur"
                ><el-icon class="cancel"> <Download /> </el-icon>下载</el-button
              >
              <div v-show="scope.row.state==2">-</div>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="centre = false">
              关闭
            </el-button>
          </span>
        </template>
      </el-dialog> -->


   </div>
</template>
<script>
import {
   WalletFilled,
   Van,
   ShoppingCartFull,
   Search,
   Download
} from "@element-plus/icons-vue";
import {
   useRouter,
   useRoute
} from "vue-router";
import {
   ref,
   onMounted,
   onDeactivated,
   onBeforeUnmount
} from 'vue';
import {
   ElMessage,
   ElMessageBox
} from "element-plus";
import {
   PFintegral,
   ZClist,
   FYintegral,
   FYdownload,
   FYdaoru,
   PFtotal,
   XZall,
   PFdetail,
   ZTtotal,
   HGtotal,
   RWall
} from "../../utils/api";
import {
   useStore
} from "vuex";
import qs from 'qs';
import moments from 'moment'
import { fa } from 'element-plus/es/locale';
// import moments from 'moment'
export default {
   name: 'JFstatistics',
   computed: {},
   setup () {
      const route = useRoute();
      // 批量派发
      const batch = ref(1)
      const duty = ref('批量派发')
      const verywhere = ref(0)
      // 弹窗下载点击
      // const copyreader=(scope)=>{
      //   window.open(scope)
      // }
      // 发布状态
      // let condition=(row, colum,cellValue)=>{
      //   console.log(cellValue)
      //    if (cellValue == 1) {
      //     return "已完成";
      //   } else if (cellValue === 2) {
      //     return "生成中";
      //   }
      // }
      // 预约下载弹窗表格提示
      // const subscribe=ref('加载中')

      // const table = ref([])
      // 获取下载列表
      // const all =()=>{
      //   XZall().then((res)=>{
      //     console.log(res)
      //     if(res.data.code==0){
      //       table.value=res.data.data.fileExportList
      //       if(table.value.length==0){
      //         subscribe.value='暂无数据'
      //       }

      //     }else{
      //       ElMessage({
      //         message: res.data.msg,
      //         type: "error",
      //         center: true,
      //       });
      //     }
      //   })
      // }



      // 浮窗点击
      // let ocean=()=>{
      //   centre.value=true
      //   all()
      // }
      // 预约下载中心弹窗
      // let centre=ref(false)
      // 下载提示弹窗
      let anew = ref(false)

      let anew1 = ref(false)
      // 搜索加载
      const formative = ref(false)



      // 下拉点击加载
      const wrought = ref(false)
      // 弹窗提示内容
      let quoth = ref('')
      let quoth1 = ref('')
      // 换购积分合计
      const barter = () => {
         let data = {
            // 开始时间 结束时间
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value
         }
         HGtotal(qs.stringify(data)).then((res) => {
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }
      // 在途积分合计
      const passage = () => {
         let data = {
            // 开始时间 结束时间
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value
         }
         ZTtotal(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }
      // 派发积分明细
      const detail = () => {
         let data = {
            // 开始时间 结束时间
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value
         }
         PFdetail(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }
      // 派发积分合计
      const payout = () => {
         let data = {
            // 开始时间 结束时间
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value
         }
         PFtotal(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
         console.log(data)
      }
      //积分日志下拉选择
      const pointsLog = (command) => {
         // console.log(command)
         if (command == 'failurePoints') {
            lose()
         } else if (command == 'purchasePoints') {
            goumai()
         } else if (command == 'returnPoints') {
            fanhuan()
         }
      }
      //积分管理下拉选择
      const pointsManagement = (command) => {
         // console.log(command)
         if (command == 'pointsRecovery') {
            withdraw()
         } else if (command == 'pointDeduction') {
            deduct()
         } else if (command == 'pointsExpired') {
            exceed()
         }
      }
      // 导出下拉按钮选择
      const handleCommand = (command) => {
         // console.log(command)
         if (command == 'personage') {
            payout()
         } else if (command == 'personage1') {
            detail()
         } else if (command == 'personage2') {
            passage()
         } else if (command == 'personage3') {
            barter()
         }
      }
      // 下拉按钮动态收回
      const around = ref(false)




      // 积分扣减跳转按钮
      const deduct = () => {
         router.push('/JFdeduct')
      }
      // 积分过期按钮跳转
      const exceed = () => {
         router.push('/JFexceed')
      }
      // 积分撤回跳转按钮
      const withdraw = () => {
         router.push('/JFrecall')
      }
      // 加载中
      const acting = ref(false)
      // 按钮禁用
      const jinyong = ref(false)
      // 工会清空
      const numerical = (e) => {
         if (e == '') {
            Hcode.value = ''
         }
      }

      // 获取任务进度列表
      const schedule = ref([])
      // 获取任务进度状态
      const condition = ref(null)

      // 获取任务进度
      const missions = () => {
         RWall().then((res) => {
            console.log(res)
            if (res.data.code == 0) {
               schedule.value = res.data.data.systemTasksList
               condition.value = schedule.value[0].state
            } else {
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }





      // 导入积分派发
      // const error=(e)=>{
      //   console.log(e)
      // }


      const pleased = ref()
      const timer = ref(0)
      const uploadHttpRequest = (data) => {
         const file = data.file;
         const formData = new FormData()
         formData.append('file', file)
         let datas = {
            excelFile: formData.get('file')
         }
         // 按钮禁用
         jinyong.value = true;
         // 按钮转圈
         acting.value = true;
         duty.value = '请稍等';
         FYdaoru(qs.parse(datas)).then((res) => {

            if (res.data.code == 0) {
               // 按钮禁用
               jinyong.value = false;
               // 按钮转圈
               acting.value = false
               duty.value = '批量派发';
               // 按钮颜色改变
               batch.value = 2
               anew1.value = true
               quoth1.value = '任务已提交，请在右侧查看进度'
               // 通过vuex传递状态 控制标签页是否能关闭
               tally.value.forEach((item) => {
                  // console.log(item)
                  if (present.value == item.path) {
                     console.log(item)
                     item.allow = 2
                  }
               })
               // 是否关闭当前页

               // gration()
               // list()
               // ElMessage({
               //     message: "任务已提交，请在右侧查看进度",
               //     type: "success",
               //     center: true,
               // });
               // 定时任务判断按钮状态  window.clearInterval(timer.value);
               timer.value = window.setInterval(function logname () {
                  // 获取任务接口
                  missions()
                  if (condition.value == 1) {
                     window.clearInterval(timer.value)
                     duty.value = '批量派发'
                     batch.value = 1
                     jinyong.value = false
                     acting.value = false
                     gration()
                     list()

                     tally.value.forEach((item) => {
                        // console.log(item)
                        if (present.value == item.path) {
                           console.log(item)
                           item.allow = 1
                        }
                     })
                  }
               }, 2000);
               pleased.value.clearFiles()
            } else if (res.data.code !== 0) {
               // gration()
               // list()
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
               acting.value = false
            }
         })
      }






      // 下载模板
      const download = () => {
         FYdownload().then((res) => {
            console.log(res)
            window.open(res.data.data.excelExampleUrl)
         })
      }






      // 失败积分日志跳转
      const lose = () => {
         router.push('/JFdefeated')
      }


      // 购买积分日志
      const goumai = () => {
         router.push('/JFpurchase')
      }

      //积分返还日志跳转
      const fanhuan = () => {
         router.push('/JFreturn')
      }


      // 数据总共条数
      let zong = ref(null)
      let own = (row, colum, cellValue) => {
         if (typeof cellValue == 'number') {
            return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
         }
      }
      let reach = (row, colum, cellValue) => {
         if (typeof cellValue == 'number') {
            return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
         }
      }
      // 列表数据循环
      const filterTableData = ref([])
      // 表格数据接口
      let list = () => {
         let data = {
            limit: branches.value,
            page: currentPage.value
         }
         FYintegral(qs.stringify(data)).then((res) => {
            console.log(res)
            if (res.data.code == 0) {
               zong.value = res.data.data.page.totalCount
               filterTableData.value = res.data.data.page.list
               console.log(filterTableData.value)
               if (filterTableData.value.length == 0) {
                  texts.value = '暂无数据'
               }
            }
         })
      }
      // 分页获取积分的派发日志
      // 动态加载数据
      let branches = ref(6)
      // 分页
      let currentPage = ref(1)
      let pagesize = ref(6)
      let handleSizeChange = (size) => {
         console.log(size)
         pagesize.value = size
         branches.value = size
         if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
            .value !== '') {
            search()
         } else {
            list()
         }
      }
      let handleCurrentChange = (size) => {
         console.log(size)
         currentPage.value = size
         texts.value = '加载中'
         if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
            .value !== '') {
            search()
         } else {
            list()
         }
      }


      // 加载中
      let texts = ref('加载中')



      let search1 = () => {
         formative.value = true
         search()
      }
      // 搜索框点击
      let search = () => {
         // 时间截止
         // 工会code
         // console.log(Hcode.value)
         // 会员编码框值
         // console.log(serial.value)
         // 姓名
         // console.log(designation.value)
         console.log('开始', a.value)
         console.log('结束', out.value)
         gration()

         // 搜索接口
         let data = {
            limit: branches.value,
            page: currentPage.value,
            // 开始时间 结束时间
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value
         }
         FYintegral(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               formative.value = false
               zong.value = res.data.data.page.totalCount
               filterTableData.value = res.data.data.page.list
               // console.log(filterTableData.value)
               if (filterTableData.value.length == 0) {
                  texts.value = '暂无数据'
               }
            }
         })
      }

      let a = ref('')
      let b = ref('')
      let c = ref('')
      let out = ref('')


      // 时间清空监听
      const immediUpdate = (e) => {
         if (e !== null) {
            a.value = new Date(e[0]).getTime() / 1000
            b.value = new Date(e[1]).getTime() / 1000
            c.value = moments(b.value * 1000).format().substring(0, 10)
            out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
         } else if (e == null) {
            a.value = ''
            b.value = ''
            c.value = ''
            out.value = ''
         }
         console.log(e)
      }

      // 搜索组织列表接口
      // 获取code 
      let Hcode = ref('')
      // 搜索组织选择值
      let ability = ref('')
      // 弹窗树形选择
      let dendrogram = (node) => {
         ability.value = node.unionName
         Hcode.value = node.unionCode
         console.log(node)
      }
      const lang = () => {
         ZClist().then((res) => {
            // console.log(res)
            texture.value = res.data.data.manageUnionList
         })
      }
      // 弹窗组织列表树形
      let texture = ref([])
      const defaultProps = {
         children: 'unionDtoList',
         label: 'unionName',
      }
      // 选择组织按钮
      let multiple = () => {
         visible.value = true
      }
      // 搜索组织弹窗
      let visible = ref(false)



      // 会员编码框
      let serial = ref('')
      // 搜索框变化值
      let orangutan = () => {
         // if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
         //   list()
         //   console.log('空了')
         // }
         console.log('变化了')
      }
      // 姓名输入框值
      let designation = ref('')


      // 总派发积分
      const departmentCount = ref(0)
      // 在途积分
      const userCount = ref(0)
      // 可派发积分
      const kepaifa = ref(0)
      // 已换购积分
      const integralCount = ref(0)
      // 派发积分 在途积分 已换购积分 
      const gration = () => {
         console.log(Hcode.value)
         let data = {
            startTime: a.value,
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code 
            unionCode: Hcode.value
         }
         PFintegral(qs.stringify(data)).then((res) => {
            // 过期积分
            verywhere.value = res.data.data.integralStatistical.invalidIntegral
            // 可派发积分
            kepaifa.value = res.data.data.distributionIntegralValue
            // 已换购积分
            integralCount.value = res.data.data.integralStatistical.consumeIntegral
            // 在途积分
            userCount.value = res.data.data.integralStatistical.effectiveIntegral
            // 总派发积分
            departmentCount.value = res.data.data.integralStatistical.statisticalIntegral
            acting.value = false
            if (kepaifa.value == 0) {
               jinyong.value = true
            } else if (kepaifa.value !== 0) {
               jinyong.value = false
            }
            console.log(res)
         })
      }


      let selectIt = ref()
      // 下拉隐藏
      let closeIt = () => {
         selectIt.value.blur();
      }


      // 时间选择
      let value1 = ref('')
      const whirl = ref(false)

      onDeactivated(() => {
         // window.clearInterval(timer.value);
         // console.log('离开了')
      })

      onBeforeUnmount(() => {
         // window.clearInterval(timer.value);
         // console.log('离开了123')
      })


      // vuex获取状态
      const store = useStore();
      // 获取当前页path
      const present = ref('')
      // 通过vuex获取标签栏
      const tally = ref([])

      onMounted(() => {
         // 获取标签栏数组
         tally.value = store.state.tagsList
         // 获取当前页path
         present.value = route.path

         // console.log(route.path)
         // console.log(store.state.tagsList)

         gration()
         lang()
         list()
      })
      // 待办内容点击事件
      const router = useRouter()
      const backlog = (row) => {
         router.push({
            path: '/homes'
         })
         console.log(row.date)
      }
      // 待办内容
      const tableData = [];
      // 头像加载失败
      const errorHandler = () => true;
      return {
         batch,
         duty,
         verywhere,
         // copyreader,
         // condition,
         own,
         quoth1,
         quoth,
         // subscribe,
         // table,
         // 浮窗点击
         // ocean,
         // 预约下载中心弹窗
         // centre,
         // 下载提示弹窗
         // anew1,
         anew,
         // 过期按钮
         exceed,
         search1,
         // 搜索加载
         formative,
         // 导出下拉按钮选择
         wrought,
         handleCommand,
         around,
         //积分日志下拉选择
         pointsLog,
         //积分管理下拉选择
         pointsManagement,
         // 积分扣减
         deduct,
         // 积分撤回             
         withdraw,
         pleased,
         acting,
         uploadHttpRequest,
         // error,
         jinyong,
         numerical,
         goumai,
         // 下载模板
         download,

         immediUpdate,
         reach,
         filterTableData,
         // 可派发积分
         kepaifa,
         // 已换购积分
         integralCount,
         // 在途积分
         userCount,
         // 派发总积分
         departmentCount,
         // 失败积分跳转
         lose,
         // 分页
         zong,
         branches,
         handleCurrentChange,
         handleSizeChange,
         currentPage,
         pagesize,
         // 组织弹窗
         ability,
         texture,
         visible,
         dendrogram,
         defaultProps,
         multiple,
         // 会员编码框
         serial,
         // 搜索框
         search,
         // 姓名框变化值
         orangutan,
         // 会员姓名
         designation,
         // 时间选择
         whirl,
         value1,
         errorHandler,
         tableData,
         backlog,
         texts,
         fanhuan,
         closeIt,
         selectIt,
      };
   },
   components: {
      Search,
      Download,
      WalletFilled,
      Van,
      ShoppingCartFull,
   },
};
</script>
<style lang='scss' scoped>
.tishi {
   width: 15px;
   height: 15px;
   margin-right: 5px;
}

.trousers {
   display: flex;
   align-items: center;
   font-size: 14px;
   margin-top: 15px;
}

.minitype {
   font-size: 16px;
}

.bulur {
   color: #409eff;
   border: none;
   --el-button-bg-color: none;
   --el-button-hover-bg-color: none;
   --el-button-hover-text-color: none;
}

.bulur:hover {
   color: #a0cfff;
}

.task {
   font-size: 14px;
}

.images {
   width: 20px;
   height: 20px;
}

.floating {
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   right: 0%;
   top: 50%;
   width: 40px;
   height: 40px;
   background-color: #409EFF;
   border-radius: 5px 0 0 5px;
   z-index: 10;
}

.loading {
   width: 8px;
   height: 8px;
   border: 2px solid #C9E3FF;
   border-top-color: transparent;
   border-radius: 100%;
   animation: circle infinite 0.75s linear;
   margin-right: 5px;
}

// 转转转动画
@keyframes circle {
   0% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(360deg);
   }
}





.guoqi {
   width: 32px;
}

::v-deep .el-col-8 {
   max-width: 25.5% !important;
}

.limit {
   background-color: #F08080;
   color: #fff;
   --el-button-active-border-color: #F08080;
   --el-button-hover-border-color: #F08080;
}

.withhold {
   background-color: #BA55D3;
   color: #fff;
   --el-button-active-border-color: #BA55D3;
   --el-button-hover-border-color: #BA55D3;
}

.recall {
   background-color: #FFA07A;
   color: #fff;
   --el-button-active-border-color: #FFA07A;
   --el-button-hover-border-color: #FFA07A;
}

::v-deep .el-table__header {
   margin: 0 !important;
}

/* 调整表格边距 */
::v-deep .el-table .el-table__cell {
   padding-top: 15px;
   padding-bottom: 15px;
}

.upload-demo {
   margin-right: 10px;
}

.el-pagination {
   margin-top: 10px;
}

/* ::v-deep .chart-card::-webkit-scrollbar {
		display: block !important;
	} */

// 派发积分模块
.payout {
   font-size: 17px;
   font-weight: bold;
   margin-right: 10px;
}

.distribute {
   display: flex;
   align-items: center;
}

.hunt {
   width: 27%;
   margin-right: 10px;
}

.grabble {
   width: 100%;
   margin-top: 20px;
   margin-bottom: 15px;
   display: flex;
   justify-content: space-between;
}

.el-card.is-always-shadow {
   width: 95%;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 20px;
   /* padding-top: 20px; */
}

::v-deep .chart-card::-webkit-scrollbar {
   display: none
}

.chart-card {
   height: calc(100vh - 150px);
   overflow: auto;
   /* padding: 10px; */
}

.el-avatar {
   --el-avatar-bg-color: #F5F5F5;
}

.statistics {
   font-size: 14px;
   color: #909399;
   margin-bottom: 15px;
}

// 右半部分下边内容
.el-table--enable-row-transition .el-table__body td.el-table__cell {
   cursor: pointer;
}

.el-checkbox__label {
   padding-left: 25px;
}

::v-deep .el-table__body {
   width: 96% !important;
   margin: auto;
   // margin-top: 10px;
   // margin-bottom: 20px;
}

.el-table__footer {
   width: 96% !important;
   margin: auto;
   // margin-top: 10px;
   // margin-bottom: 20px;
}

.card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.text {
   font-size: 14px;
}

.item {
   margin-bottom: 18px;
}

.box-card {
   width: 480px;
}

// 右半部分上边内容
.impose2 {
   margin-left: 10px;
}

.impose1 {
   margin-left: 10px;
}

.crew {
   font-size: 14px;
   color: #999;
}

.count1 {
   font-size: 25px;
   font-weight: bold;
   color: rgb(100, 213, 114);
}

.count2 {
   font-size: 25px;
   font-weight: bold;
   color: rgb(242, 94, 67);
}

.count3 {
   font-size: 25px;
   font-weight: bold;
   color: #A9A9A9;
}

.count {
   font-size: 25px;
   font-weight: bold;
   color: rgb(45, 140, 240);
}

.magnitude {
   width: 71%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

/* .el-card {
		width: 99%;
	} */

.amount {
   display: flex;
   flex-direction: row;
}

.ritherd {
   width: 98%;
   display: flex;
   flex-direction: row;
   margin-bottom: 20px;
   margin-top: 20px;
}

.union1 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: rgb(100, 213, 114);
}

.union2 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: rgb(242, 94, 67);
}

.union3 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: #A9A9A9;
}

.union {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: rgb(45, 140, 240);
}

::v-deep .el-card__body {
   padding: 0;
}

.plane {
   width: 100%;
   // margin-left: 20px;
}

// 左半部分上边内容
@media screen and (max-width: 1200px) {
   .tiem {
      display: none !important;
   }
}

.quantum {
   margin-right: 70px;
}

.tiem {
   font-size: 14px;
   color: #999;
   line-height: 25px;
   display: flex;
   align-items: center;
}

.inventory {
   width: 40%;
   overflow: hidden;
   border-radius: 4px;
}

.username {
   margin-left: 50px;
   display: flex;
   flex-direction: column;
   align-items: center;

   .name {
      font-size: 30px;
      color: #222;
   }

   .super {
      font-size: 14px;
      color: #999;
   }
}

.stration {
   padding-bottom: 20px;
   margin-bottom: 20px;
   display: flex;
   align-items: center;
   border-bottom: 2px solid #ccc;
}

.message {
   background-color: #ffffff;
   padding: 20px;
}

// 上半部分内容
// .upside {
//   display: flex;
// }
// 整体
.overall {
   /* padding-left: 10px;
		padding-top: 10px;
		// margin-top: 30px;
		height: 94%; */
   margin-top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 10px;
}

::v-deep .el-dialog__body {
   padding: 10px 10px 10px 10px !important;
   height: 215px !important;
   overflow: auto !important;
}

::v-deep .el-dialog__body::-webkit-scrollbar {
   display: none;
}
</style>
<style></style>